<template>
  <div id="pesquisa-clinica">
    <div
      v-if="mostraPesquisaRef && carregando"
      class="d-flex justify-content-center"
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">{{ $t("Carregando...") }}</span>
      </div>
    </div>

    <div
      v-if="mostraPesquisaRef && !carregando && clinicasRef.length <= 0"
      class="container"
    >
      <div class="row">
        <h1>{{ tituloPesquisa }}</h1>
      </div>

      <div class="row my-5 align-items-start align-middle">
        <div class="col w-100 clinica-pesquisa">
          <select
            class="select"
            v-model="estadoRef"
            @blur="buscarCidades(estadoRef)"
            :readonly="buscandoClinicas"
            :class="
              !$isDesktop() ? 'd-inline-block w-100 mb-3' : 'd-inline-flex w-25'
            "
          >
            <option v-if="estadoRef.length > 1" selected>
              {{ estadoRef }}
            </option>
            <option v-for="estado in estadosRef" :key="estado">
              {{ estado }}
            </option>
          </select>
          <select
            class="select"
            v-model="cidadeRef"
            :readonly="buscandoClinicas"
            :class="
              !$isDesktop() ? 'd-inline-block w-100 mb-3' : 'd-inline-flex w-50'
            "
          >
            <option selected>{{ cidadeRef }}</option>
            <option v-for="cidade in cidadesRef?.data" :key="cidade">
              {{ cidade }}
            </option>
          </select>
          <button
            v-if="!buscandoClinicas"
            :class="!$isDesktop() ? 'd-inline-block w-100' : 'd-inline-flex'"
            @click.prevent="buscarClinicasPorCidade(estadoRef, cidadeRef)"
          >
            {{ $t("Buscar") }}
          </button>
          <div v-else class="spinner-border" role="status">
            <span class="visually-hidden">{{ $t("Carregando...") }}</span>
          </div>
        </div>
        <div v-if="$isDesktop()" class="col-md-1 clinica-pesquisa">
          <h4 class="mt-2">{{ $t("OU") }}</h4>
        </div>
        <div
          v-if="$isDesktop()"
          class="col-sm-5 d-flex justify-content-center clinica-pesquisa"
        >
          <button v-if="!localizando" @click.prevent="buscarLocalizacao()">
            {{ $t("Encontre a Clínica mais próxima") }}
          </button>
          <div v-else class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{ $t("Carregando...") }}</span>
            </div>
          </div>
        </div>
        <div class="row my-5 align-items-center align-middle">
          <p>{{ erroLocalizacaoRef }}</p>
        </div>
      </div>
    </div>

    <div
      v-if="!carregando && encontrou && clinicasRef.length > 0"
      class="clinica-lista"
    >
      <h1>{{ tituloResultado }}</h1>

      <button
        v-if="mostraPesquisaRef"
        class="mt-5"
        @click.prevent="buscarNovamente()"
      >
        {{ $t("Refazer a busca") }}
      </button>

      <custom-lista :itens="clinicasRef" width="300" height="auto" />
    </div>
  </div>
</template>

<script setup>
const { t, locale } = useI18n();
const carregando = ref(true);
const encontrou = ref(true);
const buscandoClinicas = ref(false);
const localizando = ref(false);
const estadoRef = ref(t("Estado"));
const cidadeRef = ref(t("Cidade"));
const cidadesRef = ref([]);
const clinicasRef = ref([]);
const erroLocalizacaoRef = ref("");
const mostraPesquisaRef = ref(true);

const props = defineProps({
  tipo: String,
});

let tituloPesquisa = t("Encontre a Sorrifácil mais próxima de você!");
let tituloResultado = t("Escolha a clínica Sorrifácil mais perto de você.");
let botao = t("Conheça");
let link = "clinicas";

if (props.tipo == "indique") {
  tituloPesquisa = t("Selecione a clínica que deseja indicar.");
  tituloResultado = t("Escolha a clínica Sorrifácil que deseja indicar.");
  botao = t("Indique");
  link = "indique-e-ganhe";
}

onMounted(() => {
  carregando.value = false;
});

const { data: estadosRef } = await useFetch("/api/site/clinicas/estados/", {
  headers: { "Content-Language": locale },
});

const buscarCidades = async (estado) => {
  cidadesRef.value = await useFetch(`/api/site/clinicas/estados/${estado}`, {
    headers: { "Content-Language": locale },
  });
};

const buscarClinicasPorEstado = async (estado) => {
  buscandoClinicas.value = true;
  const { data: clinicas } = await useFetch(
    `/api/site/clinicas/estado/${estado}`,
    {
      headers: { "Content-Language": locale },
    }
  );
  clinicasRef.value = await listarClinicas(clinicas);
  buscandoClinicas.value = false;
  return clinicasRef.value;
};

const buscarClinicasPorCidade = async (estado, cidade) => {
  buscandoClinicas.value = true;
  const { data: clinicas } = await useFetch(
    `/api/site/clinicas/estados/${estado}/cidades/${cidade}`,
    {
      headers: { "Content-Language": locale },
    }
  );
  clinicasRef.value = await listarClinicas(clinicas);
  buscandoClinicas.value = false;
  return clinicasRef.value;
};

const buscarClinicasPorLocalizacao = async (latitude, longitude) => {
  localizando.value = true;
  const { data: clinicas } = await useFetch(
    `/api/site/clinicas/localizacao/${latitude},${longitude}`,
    {
      headers: { "Content-Language": locale },
    }
  );
  clinicasRef.value = await listarClinicas(clinicas);
  localizando.value = false;
};

const listarClinicas = async (clinicas) => {
  let lista = [];

  carregando.value = true;
  if (clinicas?.value.length > 0) {
    Object.values(clinicas.value).forEach((clinica) => {
      lista.push({
        titulo: clinica.nome,
        local: clinica?.imagem ? false : true,
        imagem: clinica?.imagem ? clinica?.imagem : "clinica-padrao",
        texto: `${clinica.endereco}, ${clinica.numero} # ${clinica.cidade} - ${clinica.bairro} # ${clinica.cep} - ${clinica.estado}`,
        subtitulo: `${clinica.telefone}#${clinica.whatsapp}`,
        botao: {
          label: botao,
          url: `/${link}/${clinica.slug}/`,
        },
        class: "clinica-cartao",
      });
    });
    encontrou.value = true;
  } else {
    encontrou.value = false;
  }
  carregando.value = false;
  return lista;
};

const buscarLocalizacao = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(buscarPosicao, mostrarErro);
  } else {
    erroLocalizacaoRef.value = t(
      "Geolocalização não é suportada neste navegador."
    );
  }
};

const buscarPosicao = (posicao) => {
  const latitude = posicao?.coords?.latitude;
  const longitude = posicao?.coords?.longitude;
  buscarClinicasPorLocalizacao(latitude, longitude);
};

const mostrarErro = (error) => {
  if (error.code === 1) {
    erroLocalizacaoRef.value = t(
      "Você não permitiu o acesso a sua localização, isso afetará o resultado da busca de clínicas."
    );
  } else {
    erroLocalizacaoRef.value = t("Erro ao obter localização!"); //+ error.message;
  }
};

const buscarNovamente = async () => {
  carregando.value = true;
  encontrou.value = true;
  clinicasRef.value = [];
  carregando.value = false;
};

// regra para não mostrar pequisa no UY, enquanto tiver só uma clínica
if (estadosRef.value?.length == 1) {
  mostraPesquisaRef.value = false;
  await buscarClinicasPorEstado(estadosRef.value[0]);
}
</script>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "PesquisaClinicas",
};
</script>
